import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
import { Row, Col, Spinner, Alert, Button, Collapse } from "react-bootstrap";
import merge from "lodash/merge";
import differenceInDays from "date-fns/differenceInDays";

import RoomCard from "../../common/RoomCard/RoomCard";
import NotAvailableMessage from "../../common/NotAvailableMessage/NotAvailableMessage";
import { UpdateSelectRoomCriteria } from "./NewSelectRoomHelpers";
import UpdateSearchModal from "./UpdateSearchModal/UpdateSearchModal";
import RoomSort from "../../common/HotelRooms/RoomSort/RoomSort";
import AccessibleFilter from "../../common/HotelRooms/AccessibleFilter/AccessibleFilter";
import RoomTypeFilter from "../../common/HotelRooms/RoomTypeFilter/RoomTypeFilter";
import OccupancyMessage from "../../common/OccupancyMessage/OccupancyMessage";
import {
  FilterTabsContainer,
  AccessibleFilterCol,
} from "../../common/HotelRooms/HelperComponents/HotelRoomComponents";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { useRoomsRates } from "../../../hooks/useRoomsRates";
import { useRoomDetail } from "../../../hooks/useRoomDetail";
import { convertArrayToObject } from "../../../utils/helpers";
import { sortRooms } from "../../../services/helpers";
import { fetchRoomsAndRatesASE } from "../../../services/rates";
import { IRoomRates } from "../../common/HotelRooms/HotelRoomsProps";
import { Constants } from "../../../@types/Constants";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { useAppSelector } from "../../../hooks";
import { selectHotelRates } from "../../../redux/slices/Rate/rate";
import { crmProfileSelector } from "../../../redux/slices/Member/member";
import {
  getMemberPoints,
  checkUserRedemptionEligiblity,
  checkUsedRedemptionPointsforReservation,
} from "../../../services/redemption";
import { INewSelectRoomProps } from "./NewSelectRoomProps";
import {
  occupancyAlert,
  promoAlert,
  roomFilters,
} from "./NewSelectRoom.module.scss";

const NewSelectRoom: React.FC<INewSelectRoomProps> = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMobileDetect();
  const checkout = useSelector((state: any) => state.editReservationCheckout);
  const index = Object.keys(checkout.Rooms)[0];
  const member = useAppSelector((state) => state.member);
  const isLoggedIn = member.isLoggedIn;
  const crmProfile = useAppSelector(crmProfileSelector);
  const [loadingRates, rooms, roomRates, queryHotel] = useRoomsRates(
    index,
    checkout
  );
  const [_loadingRoomDetail, _roomDetail, allRoomsWithoutRates] = useRoomDetail(
    {
      hotelCode: checkout.HotelCode,
      crsName: checkout.Crs,
    }
  );
  const [roomRatesUpdatedAvailability, setRoomRatesUpdatedAvailability] =
    useState(roomRates);
  const checkoutRoomsArray = Object.values(checkout.Rooms);
  const [_roomsToDisplay, setRoomsToDisplay] = useState([]);
  const [fetchedRoomRates, setFetchedRoomRates] = useState([]);
  const [showNoRoomsmessage, setShowNoRoomsmessage] = useState(false);
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isGroupCodeValid, setIsGroupCodeValid] = useState(false);
  const [showOfferMessage, setShowOfferMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedRoom, _updateSelectRoom] = useState(
    checkout.Rooms[index] || null
  );
  const [accessibleFilter, setAccessibleFilter] = useState(false);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [filterRoomType, setFilterRoomType] = useState("");
  const [filterOccupancy, _setFilterOccupancy] = useState("");
  const [showRoomTypeFilter, setShowRoomTypeFilter] = useState(false);
  const [showFilterTabs, setShowFilterTabs] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [openFilter, setOpenFilter] = useState(false);
  const [sortOrderValue, setSortOrderValue] = useState("Lowest Price");

  const [lrrSelected, setLrrSelected] = useState(false);

  const rates = useAppSelector(
    selectHotelRates([checkout.HotelCode as string])
  );
  const redemptionItem = rates[checkout.HotelCode as string]?.redemptionItem;
  const HandleLrrSelect = (value) => {
    setLrrSelected(value);
  };

  const onRoomsLoad = (
    offerAvailable: boolean,
    rate: string,
    discountType: string
  ) => {
    if (!offerAvailable) {
      const errorMsg = (
        <>
          Your {discountType} {rate?.toUpperCase()} is unavailable for the
          selected dates. We are showing lowest available rates.
        </>
      );
      setShowOfferMessage(true);
      setMessage(errorMsg);
    }
  };

  const handleUpdateSearchValues = (updatedcheckout: any) => {
    const { promotionCode, groupCode, discount } = updatedcheckout;
    const newEditReservationRate =
      promotionCode || groupCode || discount || lrrSelected
        ? null
        : props.reservation.ratePlanCode;
    setShowOfferMessage(false);
    const pointsRequiredPerDay = parseInt(redemptionItem?.currencyRequired);
    const numOfNights = differenceInDays(
      new Date(checkout.End),
      new Date(checkout.Start)
    );
    const totalUserPoints = checkout?.totalUserPoints || 0;
    const isEligible = checkUserRedemptionEligiblity(
      totalUserPoints,
      pointsRequiredPerDay,
      numOfNights
    );
    const newupdatedCheckout = {
      ...updatedcheckout,
      EditReservationRate: newEditReservationRate,
      isEligible,
      requiredPoints: pointsRequiredPerDay * numOfNights,
    };
    if (isMobile) {
      newupdatedCheckout.isModalOpen = !checkout.isModalOpen;
    }
    dispatch(setEditReservationCheckout(newupdatedCheckout));
  };

  const getPoints = async (memberId: string) => {
    try {
      const response = await getMemberPoints(memberId);
      const numOfNights = differenceInDays(
        new Date(checkout.End),
        new Date(checkout.Start)
      );
      const pointsRequiredPerDay = parseInt(redemptionItem?.currencyRequired);
      const reservationId = checkout.originalReservation.confirmationID;
      const pointUsedByUser =
        checkUsedRedemptionPointsforReservation(reservationId);
      const currentUserPoints = response?.memberPointsData
        ? parseInt(response?.memberPointsData?.available)
        : 0;
      const totalUserPoints = pointUsedByUser + currentUserPoints;
      const isEligible = checkUserRedemptionEligiblity(
        totalUserPoints,
        pointsRequiredPerDay,
        numOfNights
      );
      const newCheckout = {
        ...checkout,
        isEligible,
        requiredPoints: pointsRequiredPerDay * numOfNights,
        pointsUsedByUser: pointUsedByUser,
        totalUserPoints: totalUserPoints,
      };
      dispatch(setEditReservationCheckout(newCheckout));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Trigger the getPoints function when the member is logged in and the memberId changes
    const memberId = crmProfile?.memberId;
    if (isLoggedIn && memberId) {
      getPoints(memberId);
    }
  }, [isLoggedIn, crmProfile]);

  useEffect(() => {
    setShowNoRoomsmessage(false);
    if (rooms && rooms.length) {
      //verifiying whether promo code is applicable or not
      const roomRatesFetched = fetchRoomsAndRatesASE(
        rooms,
        checkout.discount,
        checkout.promotionCode,
        checkout.groupCode
      );
      const discountInSearch = checkout.groupCode || checkout.promotionCode;
      if (discountInSearch) {
        setSortOrder("asc");
        const offerAvailable =
          roomRatesFetched &&
          roomRatesFetched[0] &&
          roomRatesFetched[0].FromRatePromotional;
        const discountType = checkout.promotionCode
          ? "Promo code"
          : "Group code";
        setIsPromoValid(offerAvailable);
        offerAvailable
          ? setSortOrderValue("Promotional Price")
          : setSortOrderValue("Lowest Price");
        if (checkout.groupCode) {
          setIsGroupCodeValid(offerAvailable);
        }
        onRoomsLoad &&
          onRoomsLoad(offerAvailable, discountInSearch, discountType);
      } else {
        setSortOrderValue("Lowest Price");
      }

      // update rooms availability
      roomRates &&
        (roomRates as any[]).forEach((r: any) => {
          const selectedRooms = checkoutRoomsArray.filter((cRoom: any) => {
            return cRoom.room && cRoom.room.RoomCode == r.RoomCode;
          });
          if (r.RoomCode != selectedRoom.room.RoomCode) {
            r.quantity = r.quantity - selectedRooms.length;
          }
        });
      setRoomRatesUpdatedAvailability(roomRates);
    } else {
      setRoomRatesUpdatedAvailability(null);
      setShowNoRoomsmessage(true);
    }
  }, [rooms, roomRates]);

  useEffect(() => {
    if (fetchedRoomRates && rooms) {
      setShowNoRoomsmessage(false);
      let roomArray = [...fetchedRoomRates];
      if (roomArray && roomArray.length) {
        roomArray = roomArray.filter((room: IRoomRates) => {
          return room.quantity > 0;
        });

        //ASE - review
        if (filterRoomType) {
          roomArray = roomArray.filter((room: IRoomRates) => {
            //Handling the case Where the details API response contains rooms which has empty/null amenities or doesn't contain either of rooms or suites as amenity. These rooms will be shown for all Room Type filter options
            return room.RoomType?.length === 0 ||
              room.RoomType?.includes(null) ||
              !["Suites", "Rooms"].some((element) =>
                room.RoomType?.includes(element)
              )
              ? true
              : room.RoomType && room.RoomType.includes(filterRoomType);
          });
        }

        const filterAccessibleRooms = (fieldValue: boolean) =>
          roomArray.filter((room: IRoomRates) => {
            return room.accessible === fieldValue;
          });

        if (!accessibleFilter) {
          let filterByAccessible = filterAccessibleRooms(false);
          if (!filterByAccessible.length) {
            filterByAccessible = roomArray.filter(
              (room: IRoomRates) =>
                room.accessible === true || room.name.includes("Accessible")
            );
            setAccessibleFilter(true);
          }
          roomArray = [...filterByAccessible];
        } else {
          setAccessibleFilter(true);
        }
        if (filterOccupancy) {
          roomArray = roomArray.filter((room: IRoomRates) => {
            return room.occupancy == filterOccupancy;
          });
        }
      }
      if (roomArray.length < 1) {
        setShowNoRoomsmessage(true);
      }
      // Sort based on selected sort option
      if (sortOrderValue === "Promotional Price") {
        // Separate promoRooms and remainingRooms based on promoCode
        const promoRooms = roomArray.filter(
          (room: IRoomRates) => room.FromRatePromotional
        );
        const remainingRooms = roomArray.filter(
          (room: IRoomRates) => !room.FromRatePromotional
        );
        // Sort promoRooms based on promotional rates (FromRate or BaseRate if available)
        promoRooms.sort((a: IRoomRates, b: IRoomRates) => {
          const aRate = a.BaseRate ?? a.FromRate;
          const bRate = b.BaseRate ?? b.FromRate;
          return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
        });

        remainingRooms.sort((a: IRoomRates, b: IRoomRates) => {
          const aRate = a.BaseRate ?? a.FromRate;
          const bRate = b.BaseRate ?? b.FromRate;
          return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
        });

        // Merge promoRooms and remainingRooms back together
        roomArray = [...promoRooms, ...remainingRooms];
      } else if (sortOrderValue === "Lowest Price") {
        // Sort by BaseRate in ascending or descending order
        roomArray.sort((a: IRoomRates, b: IRoomRates) =>
          sortOrder === "asc"
            ? a.BaseRate - b.BaseRate
            : b.BaseRate - a.BaseRate
        );
      } else if (sortOrderValue === "Highest Price") {
        // Sort by BaseRate in ascending or descending order
        roomArray.sort((a: IRoomRates, b: IRoomRates) =>
          sortOrder === "asc"
            ? b.BaseRate - a.BaseRate
            : a.BaseRate - b.BaseRate
        );
      }
      // Update the rooms state with the sorted rooms
      setFilteredRooms(roomArray);
    }
  }, [
    fetchedRoomRates,
    accessibleFilter,
    filterRoomType,
    sortOrder,
    sortOrderValue,
  ]);
  useEffect(() => {
    if (allRoomsWithoutRates) {
      if (roomRatesUpdatedAvailability) {
        const ratesObject = convertArrayToObject(
          roomRatesUpdatedAvailability,
          "code"
        );
        Object.keys(ratesObject).map((key: string) => {
          if (allRoomsWithoutRates && allRoomsWithoutRates[key]) {
            ratesObject[key].RoomType = ratesObject[key].amenities.includes(
              "Suite"
            )
              ? "Suites"
              : "Rooms";
            // modifying galleryImages in room object according to Image carousel format
            ratesObject[key].galleryImages = allRoomsWithoutRates[key].imageUrls
              .length
              ? allRoomsWithoutRates[key].imageUrls.map((image: string) => ({
                  url: image,
                  alt: image,
                }))
              : [];
          }
        });
        merge(ratesObject, allRoomsWithoutRates);
        let showTab = false;
        let allRoomTypes =
          ratesObject &&
          Object.keys(ratesObject).map((k: string) => ratesObject[k].RoomType);
        allRoomTypes = allRoomTypes.flat();
        if (
          allRoomTypes.includes(Constants.ROOM_AMINITY_ID) &&
          allRoomTypes.includes(Constants.SUITE_AMINITY_ID)
        ) {
          showTab = true;
        }
        setShowFilterTabs(true);
        setShowRoomTypeFilter(showTab);
        const mergedRoomsArray = Object.values(ratesObject);
        const sortedRooms = sortRooms(mergedRoomsArray); //mergedRoomsArray.sort(r => r.Availability > 0 && -1 );
        setFetchedRoomRates(sortedRooms);
      } else {
        const sortedRooms = sortRooms(Object.values(allRoomsWithoutRates));
        setRoomsToDisplay(sortedRooms);
        setFetchedRoomRates(false);
      }
    }
  }, [allRoomsWithoutRates, roomRatesUpdatedAvailability]);

  const handleTogleMobileFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleSortChange = (sortOrder: string) => {
    setSortOrder(sortOrder);
  };

  const handleSelectRoom = async (event: any) => {
    const dataset = event.target.dataset;
    const roomCode = dataset.room;
    const room = (roomRatesUpdatedAvailability as any[]).filter(
      (roomRate: any) => roomRate.RoomCode === roomCode
    )[0];
    const promotionCode = checkout.promotionCode;
    const groupCode = checkout.groupCode;
    const discount = checkout.discount;
    const newEditReservationRate =
      promotionCode || groupCode || discount
        ? null
        : lrrSelected
        ? null
        : props.reservation.ratePlanCode;
    const rateCode =
      newEditReservationRate || promotionCode || groupCode || discount;
    let rate = room.Rates.filter(
      (rateObject: any) => rateObject.RateCode === rateCode
    )[0];
    if (!rate) {
      rate = room.Rates.filter(
        (rateObject: any) => rateObject.RateCode === room.FromRateCode
      )[0];
    }
    let currentRoom = checkout.Rooms[index];
    currentRoom = { ...currentRoom, rate: rate, room: room, services: null };
    const updatedCheckout = {
      ...checkout,
      Rooms: { ...checkout.Rooms, [index]: currentRoom },
      Step: "select-rate",
      hotelLocation: queryHotel?.hotelLocation,
      Brand: queryHotel?.brand.code,
      redemptionItem: queryHotel?.redemptionItem,
      groupCode: isPromoValid ? checkout.groupCode : null,
      promotionCode: isPromoValid ? checkout.promotionCode : null,
    };
    dispatch(setEditReservationCheckout(updatedCheckout));
    navigate("/edit-reservation/select-rate");
  };

  const getRoomsCard = () => {
    if (filteredRooms && filteredRooms.length > 0) {
      return filteredRooms.map((room: any, idx: any) => {
        const selected = false;
        return (
          <Col key={room.RoomCode} className="mb-4">
            <RoomCard
              room={room}
              selectRoom={handleSelectRoom}
              isGroupCodeValid={isGroupCodeValid}
              selected={selected}
              page={"edit-reservation"}
              roomCardId={idx + 1}
              redemptionItem={redemptionItem}
              hotel={checkout?.originalReservation?.hotel}
            />
          </Col>
        );
      });
    }
  };

  const UpdateComponent = isMobile
    ? UpdateSearchModal
    : UpdateSelectRoomCriteria;

  return (
    <>
      <UpdateComponent
        show={props.showUpdateCriteriaModal}
        onHide={props.updateModalView}
        checkout={checkout}
        reservation={props.reservation}
        updateSearch={handleUpdateSearchValues}
        HandleLrrSelect={HandleLrrSelect}
      />
      {showFilterTabs ? (
        <>
          <Collapse in={openFilter} className="d-lg-block">
            <div
              id="roomFilters"
              className={`room-filters-container mb-4 ${roomFilters}`}
            >
              <Row className="search-filters g-0" id="search-filters">
                <Col
                  lg={{ span: 5 }}
                  className={`text-left mt-3 mt-lg-0 ${openFilter && "mb-4"}`}
                >
                  <RoomSort
                    sortOrder={sortOrder}
                    handleSortChange={handleSortChange}
                    setSortOrderValue={setSortOrderValue}
                    sortOrderValue={sortOrderValue}
                  />
                </Col>
                <FilterTabsContainer>
                  <Row>
                    {showRoomTypeFilter ? (
                      <>
                        <Col lg={6}>
                          <AccessibleFilter
                            accessibleFilter={accessibleFilter}
                            setAccessibleFilter={setAccessibleFilter}
                          />
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex justify-content-end filterButton"
                        >
                          <RoomTypeFilter
                            setFilter={setFilterRoomType}
                            filterRoomType={filterRoomType}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={12}>
                          <div className="d-flex justify-content-end">
                            <AccessibleFilter
                              accessibleFilter={accessibleFilter}
                              setAccessibleFilter={setAccessibleFilter}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </FilterTabsContainer>
              </Row>
              <Button
                className="close-filters w-100 d-lg-none mt-3"
                variant="secondary"
                onClick={handleTogleMobileFilter}
              >
                Close
              </Button>
            </div>
          </Collapse>
          {isMobile ? (
            showRoomTypeFilter ? (
              <div className={roomFilters}>
                <Row className="g-0">
                  <Col xs={6} className="text-left mt-lg-0">
                    <RoomSort
                      sortOrder={sortOrder}
                      handleSortChange={handleSortChange}
                      setSortOrderValue={setSortOrderValue}
                      sortOrderValue={sortOrderValue}
                    />
                  </Col>
                </Row>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                <Row className="mb-4">
                  <Col lg={6} xs={7}>
                    <AccessibleFilter
                      accessibleFilter={accessibleFilter}
                      setAccessibleFilter={setAccessibleFilter}
                    />
                  </Col>
                  <Col lg={6} xs={5} className={`text-right filterButton`}>
                    <RoomTypeFilter
                      setFilter={setFilterRoomType}
                      filterRoomType={filterRoomType}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <div className={roomFilters}>
                <Row className="mb-4">
                  <Col xs={6} style={{ width: "auto !important" }}>
                    <RoomSort
                      sortOrder={sortOrder}
                      handleSortChange={handleSortChange}
                      setSortOrderValue={setSortOrderValue}
                      sortOrderValue={sortOrderValue}
                    />
                  </Col>

                  <AccessibleFilterCol>
                    <AccessibleFilter
                      accessibleFilter={accessibleFilter}
                      setAccessibleFilter={setAccessibleFilter}
                    />
                  </AccessibleFilterCol>
                </Row>
              </div>
            )
          ) : null}
        </>
      ) : null}
      {loadingRates ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : showNoRoomsmessage ? (
        <Col>{showNoRoomsmessage && <NotAvailableMessage />}</Col>
      ) : (
        <React.Fragment>
          {showOfferMessage && !isPromoValid ? (
            <Row className="pt-3">
              <Col>
                <Alert
                  variant={"danger"}
                  onClose={() => setShowOfferMessage(false)}
                  dismissible
                  transition
                  className={promoAlert}
                  id={"promo-alert-message"}
                >
                  <FontAwesomeIcon icon="exclamation-circle" className="me-2" />
                  {message}
                </Alert>
              </Col>
            </Row>
          ) : null}
          <Row className={occupancyAlert}>
            <Col>
              <OccupancyMessage
                page="selectroom_edit_reservation"
                crsCode={props.reservation.HotelCode || ""}
                className={"occupancyInfo"}
              />
            </Col>
          </Row>
          <Row lg={3} xs={1}>
            {getRoomsCard()}
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default NewSelectRoom;
